<template>
    <v-container  fluid>
        <v-card>
        <v-row>
            <v-col cols="12">
            <v-toolbar elevation="0" >
    <template v-if="!$vuetify.breakpoint.smAndDown">
        
    <v-toolbar-title class="pl-4" >
        Configuración de Horarios
    </v-toolbar-title>
   
    <v-spacer></v-spacer>

    </template>
    <v-btn  color="primary darken-4" @click="editClasses=true" dark :loading="loading" class="mx-auto mr-2"  > 
        <v-icon left>mdi-cog</v-icon>
        Clases
    </v-btn>

    <v-dialog v-model="editClasses" max-width="600px">
<v-card>
<v-card-title >
Configuración de Clases
<v-spacer></v-spacer>
<v-tooltip bottom>
<template v-slot:activator="{ on, attrs }">
<v-btn icon v-bind="attrs" v-on="on" @click="editClasses = false">
<v-icon>mdi-close</v-icon>
</v-btn>
</template>
<span>Cerrar</span>
</v-tooltip>
</v-card-title>
<v-card-text>
<v-row no-gutters> 
<v-col cols="12" sm="6">

<v-form ref="formClasses">
<v-text-field 
v-model="type" 
label="Tipo"
filled
dense
:rules="requiredRules" 
clearable
></v-text-field>

</v-form>
</v-col>
<v-col cols="12" sm="6">
<v-menu
v-model="menu"
:close-on-content-click="false"
:nudge-width="200"
offset-y
>
<template v-slot:activator="{ on, attrs }">
    <v-btn :color="color"  block x-large
    v-bind="attrs"
    v-on="on">
        <v-icon left>mdi-palette</v-icon>
        Seleccionar color
</v-btn>
</template>
<v-color-picker 
  v-model="color"
  hide-inputs
></v-color-picker>
</v-menu>
</v-col>
</v-row>
<v-row>
<v-col cols="12">
<v-btn 
block
@click="addItem()"
color="success"
dark
:loading="loading"
>
<v-icon left>mdi-plus</v-icon>
Agregar
</v-btn>
</v-col>
</v-row>
<v-row>
<v-col cols="12">
<v-divider></v-divider>
</v-col>
</v-row>
<v-row>
<v-col cols="12">
<v-list dense>
<v-list-item 
  v-for="(item, index) in itemsTypes" 
  :key="index"
>
    <v-list-item-avatar>
        <v-avatar :color="item.color" size="30">
        </v-avatar>
    </v-list-item-avatar>
  <v-list-item-content>
    <v-list-item-title>{{ item.name }}</v-list-item-title>
  </v-list-item-content>
  <v-list-item-action>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="removeItem(index)" color="error" :loading="loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <span>Eliminar Clase</span>
    </v-tooltip>
  </v-list-item-action>
</v-list-item>
</v-list>
</v-col>
</v-row>
</v-card-text>
</v-card>
</v-dialog>



    <v-btn  color="success" @click="storeSchedule(true)" dark :loading="loading" class="mx-auto" > 
                    <v-icon left>mdi-content-save</v-icon>
                    Guardar</v-btn>

</v-toolbar>


</v-col>
        </v-row>
       
        <v-tabs v-model="tab" grow>
            <v-tab>
                <v-icon left>mdi-calendar-clock</v-icon>
                Horarios
            </v-tab>
            <v-tab>
                <v-icon left>mdi-calendar-star</v-icon>
                Dias Especiales
            </v-tab>

            <v-tab-item>

                <v-form ref="schedule">
      <v-row no-gutters>
           
                            

                <v-col v-if="schedule && itemsTypes.length>0 && $vuetify.breakpoint.smAndDown" v-for="day in days" :key="day" cols="12" sm="6" md="4" lg="4">

                    <v-card outlined class="rounded-0">
                        <v-card-title>{{ day }}</v-card-title>
                        <v-card-text>
                            <v-row v-for="(range, index) in schedule[day]" :key="index"  no-gutters   class="mb-4">

                                <v-card >

                                    <v-row no-gutters>
                                <v-col cols="6">
                                    <v-combobox filled dense label="Tipo" v-model="range.type" :rules="requiredRules"  hide-details="" :items="itemsTypes" item-text="name" item-value="name" @change="update" :return-object="false">
                                       <template v-slot:prepend-inner>
                                            <v-avatar :color="itemsTypes.find(item => item.name == range.type)?.color || ''" size="25">
                                                
                                            </v-avatar>
                                       </template>
                                    </v-combobox>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="6">
                                    
                                    <v-text-field v-model="range.duration" label="Duración" type="number" :rules="requiredRules" hide-details="" dense  filled/>
                                </v-col>
                                <v-col cols="5" >

                                    <v-text-field filled hide-details=""  label="Hora inicio" v-model="range.start" type="time" :rules="requiredRules" />
                                </v-col>
                                <v-divider vertical></v-divider>

                                <v-col cols="5">                            
                                    <v-text-field filled hide-details="" label="Hora finalización" v-model="range.end" type="time" :rules="requiredRules" />
                                </v-col>
                                <v-col cols="2" class="text-center">
                                    <v-btn @click="removeScheduleRange(day, index)" small  color="error" class="mt-4 mx-auto" icon > 
                                    <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                            </v-card>
                            
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4" lg="4">
                                    <v-btn @click="addScheduleRange(day)" x-small>
                                    <v-icon left>mdi-plus</v-icon>
                                        agregar clase
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" v-if="schedule && itemsTypes.length>0 && !$vuetify.breakpoint.smAndDown"> 

                    <v-slide-group mandatory class="mx-auto" v-model="selectedDaySlide" center-active show-arrows>
                        <v-slide-item v-for="day in days" :key="day" v-slot="{ active, toggle }">
                            <v-card outlined class="ma-2" width="300" @click="toggle"  v-bind:class="{'rounded-0':$vuetify.breakpoint.smAndDown}"  :ripple="false">
                        <v-card-title>{{ day }}</v-card-title>
                        <v-card-text>
                            <v-row v-for="(range, index) in schedule[day]" :key="index"  no-gutters   class="mb-4">

                                <v-card >

                                    <v-row no-gutters>
                                <v-col cols="6">
                                    <v-combobox filled dense label="Tipo" v-model="range.type" :rules="requiredRules"  hide-details="" :items="itemsTypes" item-text="name" item-value="name" @change="update" :return-object="false">
                                       <template v-slot:prepend-inner>
                                            <v-avatar :color="itemsTypes.find(item => item.name == range.type)?.color || ''" size="25">
                                                
                                            </v-avatar>
                                       </template>
                                    </v-combobox>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="6">
                                    
                                    <v-text-field v-model="range.duration" label="Duración" type="number" :rules="requiredRules" hide-details="" dense  filled/>
                                </v-col>
                                <v-col cols="5" >

                                    <v-text-field filled hide-details=""  label="Hora inicio" v-model="range.start" type="time" :rules="requiredRules" />
                                </v-col>
                                <v-divider vertical></v-divider>

                                <v-col cols="5">                            
                                    <v-text-field filled hide-details="" label="Hora finalización" v-model="range.end" type="time" :rules="requiredRules" />
                                </v-col>
                                <v-col cols="2" class="text-center">
                                    <v-btn @click="removeScheduleRange(day, index)" small  color="error" class="mt-4 mx-auto" icon > 
                                    <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                            </v-card>
                            
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4" lg="4">
                                    <v-btn @click="addScheduleRange(day)" x-small>
                                    <v-icon left>mdi-plus</v-icon>
                                        agregar clase
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                        </v-slide-item>
                    </v-slide-group>
                </v-col>


            </v-row>
            </v-form>
            </v-tab-item>

            <v-tab-item class="pa-2">

                    <v-btn block color="primary" @click="futureModifications=true" class="pa-4">
                    <v-icon left>mdi-plus</v-icon>
                    Agregar
                </v-btn>


                <v-dialog v-model="futureModifications" max-width="400">
                    <v-card>
                        <v-card-title>
                            Agregar Cambio Futuro
                            <v-spacer></v-spacer>
                            <v-btn icon @click="futureModifications = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text>

                            <v-form ref="form" >
                                <v-radio-group  :rules="requiredRules" v-model="selectedDay.type" row hide-details="" class="mb-4">
                                    <v-radio label="Feriado" value="Feriado"></v-radio>
                                    <v-radio label="Dia Especial" value="Dia Especial"></v-radio>
                                    <v-radio label="Emergencia" value="Emergencia"></v-radio>
                                    <v-radio label="Evento" value="Evento"></v-radio>
                                </v-radio-group>

                                 <v-divider v-show="selectedDay.type=='Feriado'"></v-divider>
                                <v-radio-group v-model="selectedDay.isRecurrent" row v-show="selectedDay.type=='Feriado'" hide-details="" class="mb-4">
                                   
                                    <v-radio label="No recurrente" :value="false"></v-radio>
                                    <v-radio label="Recurrente" :value="true"></v-radio>
                                </v-radio-group>

                            <v-text-field dense label="Titulo"  :rules="requiredRules" filled hide-details="" prepend-inner-icon="mdi-pencil" v-model="selectedDay.title"></v-text-field>

                              <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                     filled hide-details=""
                                    v-model="selectedDay.selectedDate"
                                    label="Selecciona fecha"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly dense
                                    v-bind="attrs"  :rules="requiredRules"
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="selectedDay.selectedDate" @input="menu = false"></v-date-picker>
                                </v-menu>

                              

                              
                                 <v-btn block @click="addTimeRange" v-if="selectedDay.type=='Dia Especial'" class="mt-4 mb-2">
                                
                <v-icon left>mdi-plus</v-icon>
                agregar clase

            </v-btn>
                     
            <v-row v-for="(range, index) in selectedDay.timeRanges" :key="index" >
                <v-col cols="6">
                   <v-autocomplete
                    v-model="range.type"
                    :items="itemsTypes"
                    item-text="name"
                    item-value="name"
                    label="Tipo"
                    hide-details=""
                    :rules="requiredRules"
                    dense
                    filled
                    >
                    </v-autocomplete>


                </v-col>

                <v-col cols="6">
                    <v-text-field label="Duración" v-model="range.duration" type="number" hide-details="" :rules="requiredRules" dense filled/>
                </v-col>
                


              <v-col cols="5">
                                        <v-text-field label="Hora Inicio" v-model="range.start" type="time" hide-details=""  :rules="requiredRules"/>
                  </v-col>
              <v-col cols="5" >
                                        <v-text-field label="Hora Fin" v-model="range.end" type="time" hide-details="" :rules="requiredRules" />
                  </v-col>
              <v-col cols="2" >
                <v-btn icon  color="red" @click="removeTimeRange(index)" class="mx-auto">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>


</v-form>

<v-divider class="mt-8"></v-divider>

                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :loading="loading" color="success" @click="selectedDay.type=='Feriado' ? saveHoliday() : saveSpecialDay()" dark>
                                <v-icon left>mdi-content-save</v-icon>
                                
                                Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            <v-data-table
                :headers="headers"
                fixed-header
                :items="specialDays"
                class="elevation-1"
                mobile-breakpoint="0"
              
                @click:row="edit"
                sort-by="date"
                >
            
                <template v-slot:item.type="{ item }">
                    <v-chip :color="getColor(item)" dark>
                        {{ item.type }}
                    </v-chip>
                </template>


                <template v-slot:item.timeRanges="{ item }" >
                    <v-chip v-for="(range, rangeIndex) in item.timeRanges" :key="rangeIndex" color="primary" class="mr-2">
                        {{ range.start }} - {{ range.end }}
                        {{ range.type }}
                    </v-chip>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-btn x-small fab @click.stop="removeSpecialDay(item)" color="red" elevation="0">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            </v-tab-item>
        </v-tabs>
    </v-card>
    </v-container>
</template>
<script>

import { getFirestore, collection, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction } from "firebase/firestore";

export default {
    data() {
        return {
            selectedDaySlide:null,
            editClasses: false,
            itemsTypes: [
            ],
            days: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"],
            schedule: null,
            selectedDate: '',
            timeRanges: [],
            specialDays: [],
            menu: false,
            tab: 0,
            futureModifications: false,
            type: null,
            title: null,
            isRecurrent: false,

            requiredRules: [
                v => !!v || 'Este campo es requerido',
            ],
            loading: true,

            selectedDay: {
                date: null,
                timeRanges: [],
                title: null,
                type: null,
                isRecurrent: false,
            },


            color:'grey darken-2'
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: 'Fecha',
                    align: 'start',
                    sortable: true,
                    value: 'date',
                },
                {
                    text: 'Tipo',
                    value: 'type',
                    sortable: false,
                },
                { text: 'Titulo', value: 'title' },
                { text: 'Acciones', value: 'actions', sortable: false },
            ]
        }
    },
    mounted() {
        this.getScheduleSetup()


        //next check what day is today and select it on the slide
        let today = new Date().getDay();
        this.selectedDaySlide = today - 1;




    },
    methods: {
        update() {
            this.$forceUpdate()
        },
        getColor(item) {
            switch (item.type) {
                case 'Feriado':
                    return 'green';
                case 'Dia Especial':
                    return 'primary';
                case 'Emergencia':
                    return 'red';
                case 'Evento':
                    return 'grey';
            }
        },
        getColorRange(type) {

            if (!type) {
                return 'primary';
            }

            let item = this.itemsTypes.find(item => item.name == type.name);
            return item ? item.color : '';
        },
       addTimeRange() {
            this.selectedDay.timeRanges.push({ start: '', end: '', type: null, duration: null

            });
        },
        removeTimeRange(index) {
            this.selectedDay.timeRanges.splice(index, 1);
        },
        async saveSpecialDay() {

            // Validate and save special day
            if(this.$refs.form.validate() == false) {
                return;
            }   

            if (this.selectedDay.timeRanges.length == 0 &&
                this.type == 'Dia Especial'

            ) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Debe agregar al menos un rango de tiempo',
                    type: 'error'
                });
                return;
            }



              this.loading = true;


            // check if the selected date already exists and update it, if not, add it
            let exists = this.specialDays.find(day => day.date == this.selectedDay.selectedDate);
            if (exists) {
                exists.ranges = this.selectedDay.timeRanges;
                exists.title = this.selectedDay.title;
                exists.type = this.selectedDay.type;
            } else{
               this.specialDays.push({
                    type: this.selectedDay.type,
                    date: this.selectedDay.selectedDate,
                    ranges: this.selectedDay.timeRanges,
                    title: this.selectedDay.title,
                });

            }
           

            await this.storeSchedule();
           
            // Reset for next entry
            this.selectedDay.selectedDate = null;
            this.selectedDay.timeRanges = [];
            this.selectedDay.title = null;
            this.selectedDay.type = null;
            this.timeRanges = [];
            
                
        },
        async saveHoliday() {

            // Validate and save special day
            if (this.$refs.form.validate() == false) {
                return;
            }

            this.loading = true;


            // check if the selected date already exists and update it, if not, add it 
            let exists = this.specialDays.find(day => day.date == this.selectedDay.selectedDate);
            if (exists) {
                exists.title = this.selectedDay.title;
                exists.type = this.selectedDay.type;
                exists.recurrent = this.selectedDay.isRecurrent;
            } else{
                this.specialDays.push({
                    type: this.selectedDay.type,
                    date: this.selectedDay.selectedDate,
                    ranges: [],
                    title: this.selectedDay.title,
                    recurrent: this.selectedDay.isRecurrent
                });
            }
            await this.storeSchedule()

           
            // Reset for next entry
            this.selectedDay.selectedDate = null;
            this.selectedDay.timeRanges = [];
            this.selectedDay.title = null;
            this.selectedDay.type = null;
            this.timeRanges = [];
            this.isRecurrent = false;
            
        },
        
        async getScheduleSetup() {
            const db = getFirestore()
            const docRef = doc(db, `configurations/schedule`);
            const docSnap = await getDoc(docRef);
            let schedule = docSnap
            if(!schedule.exists()) {
                let schedule = {
                    Lunes: [],
                    Martes: [],
                    Miercoles: [],
                    Jueves: [],
                    Viernes: [],
                    Sabado: [],
                    Domingo: [],
                    specialDays:[],
                    classesConfiguration:[]
                }
                setDoc(docRef, schedule);
            } else {  
                this.schedule = schedule.data();
                this.itemsTypes = this.schedule.classesConfiguration;
                this.specialDays = this.schedule.specialDays.sort((a, b) => a.date - b.date) || [];
            }

            this.loading = false;
        },
          addSpecialDay(date, start, end) {
            this.specialDays.push({ date, start, end });
        },
        async removeSpecialDay(item) {

            let confirm = await this.$confirm( "¿Está seguro que desea eliminar esta modificación?",
                     {
            color: "error",
            title: "Eliminar Modificación",
            icon: "mdi-alert-circle",
            buttonTrueText: "Si",

                }
            );

            if (confirm) {
                let exist = this.specialDays.find(day => day.date == item.date);
                if (exist) {
                    this.specialDays.splice(this.specialDays.indexOf(exist), 1);
                }
                await this.storeSchedule();
            }

        },
        addScheduleRange(day) {
            this.schedule[day].push({color:"primary",type:null, start: '', end: '' });
        },
        removeScheduleRange(day, index) {
            this.schedule[day].splice(index, 1);
        },
        async storeSchedule(confirmation = false) {

            if(confirmation){
                let confirm = await this.$confirm( "¿Está seguro que desea guardar los cambios en la agenda?<br><br>Se recomienda notificar a los usuarios de los cambios si los mismos afectan reservas ya realizadas.<br><br> Ya que si quedan fuera del rango de horarios disponibles, podrían ser eliminadas automáticamente por el sistema.",
                {
                    color: "error",
                    title: "Advertencia",
                    icon: "mdi-alert-circle",
                    buttonTrueText: "Si estoy seguro",
                });
            }
            
                


                

            //validate
            if (this.$refs.schedule.validate() == false) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Debe completar todos los campos',
                    type: 'error'
                });
                return;
            }

            this.loading = true;



            const db = getFirestore()
            const docRef = doc(db, `configurations/schedule`);
            //const docSnap = await getDoc(docRef);
            //let schedule = docSnap

            // Update schedule with specialDays before storing
            this.schedule.specialDays = this.specialDays;


            await setDoc(docRef, this.schedule);
            this.$notify({
                group: 'feedback',
                title: 'Éxito',
                text: 'Horario guardado con éxito',
                type: 'success'
            });

            this.loading = false;
            this.futureModifications = false;
        },
        makeAllDaysLikeThis(day) {
            let daySchedule = this.schedule[day]
            for (let i = 0; i < this.days.length; i++) {
                if(this.days[i] != day) {
                    this.schedule[this.days[i]] = JSON.parse(JSON.stringify(daySchedule))
                }
            }
        },
        edit(item) {
            this.selectedDay.selectedDate = item.date;
            this.selectedDay.timeRanges = item.ranges;
            this.selectedDay.title = item.title;
            this.selectedDay.type = item.type;
            this.selectedDay.isRecurrent = item.recurrent;

            this.futureModifications = true;
            
        },
        addItem() {
            if(this.$refs.formClasses.validate() == false) {
                return;
            }

            if (!this.color){
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Debe elegir un color para la clase',
                    type: 'error'
                });
                return;
            }
            
            this.itemsTypes.push({ name: this.type, color: this.color });
            
            this.$refs.formClasses.reset();
            this.color = 'grey darken-2';
            this.storeSchedule();
        },
        removeItem(index) {
            this.itemsTypes.splice(index, 1);

            this.storeSchedule();
        }

    },
}
</script>
